import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Root from '../../components/root';
import PostTemplateDetails from '../../components/post';

const PostTemplate = (props) => {
  const {
    post,
    allSeriesEdges: {
      nodes: allSeriesPosts,
    },
    site: {
      siteMetadata,
    },
  } = props.data;
  const { subtitle, serials, categories, } = siteMetadata;
  const { title: postTitle, description: postDescription, path, featuredImage } = post.frontmatter;
  const description = postDescription !== null ? postDescription : subtitle;
  const series = (post.frontmatter.series)
    ? {
      ...serials.find(serial => serial.id === post.frontmatter.series),
      posts: allSeriesPosts
        .filter(seriesPost => seriesPost.frontmatter.series === post.frontmatter.series),
    }
    : null;
  const category = {
    ...categories
      .find(currentCategory => currentCategory.id === post.frontmatter.category),
  };

  return (
    <Root>
      <div>
        <Helmet>
          <meta property="og:type" content="article" />
          <meta name="twitter:site" content="@_IanBelcher_" />
          <title>{postTitle}</title>
          <meta property="og:title" content={postTitle} />
          <meta name="twitter:title" content={postTitle} />
          <meta name="description" content={description} />
          <meta name="twitter:description" content={description} />
          {featuredImage && [
            <meta key={'twitter:card'} name="twitter:card" content="summary_large_image" />,
            <meta key={'og:image'} property="og:image" content={`https://ianbelcher.me${featuredImage.childImageSharp.fluid.src}`} />,
            <meta key={'twitter:image'} name="twitter:image" content={`https://ianbelcher.me${featuredImage.childImageSharp.fluid.src}`} />,
          ]}
          {!featuredImage && [
            <meta key={'twitter:card'} name="twitter:card" content="summary" />,
          ]}
          <meta property="og:url" content={`https://ianbelcher.me${path}`} />
        </Helmet>
        <PostTemplateDetails
          post={post}
          siteMetadata={siteMetadata}
          series={series}
          category={category}
        />
      </div>
    </Root>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteTitle
        subtitle
        copyright
        url
        serials {
          id
          title
          description
          hidePosts
        }
        categories {
          id
          title
          path
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        topicSlugs
        slug
      }
      frontmatter {
        type
        title
        topics
        date
        updated
        description
        category
        series
        path
        featuredImage {
          childImageSharp {
              fluid(maxWidth: 1800, quality: 70) {
                ...GatsbyImageSharpFluid
              }
          }
        }
        featuredImageAttribution
        featuredImageAttributionLink
      }
    }
    allSeriesEdges: allMarkdownRemark(
      filter: { frontmatter: { series: { ne: null }, listed: { eq: true }, draft: { ne: true } } }, sort: { order: ASC, fields: [ frontmatter___date ] }) {
      nodes {
        frontmatter {
          title
          series
          path
          date
        }
      }
    }
  }
`;
