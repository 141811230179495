import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import dateFormatter from '../../helpers/date-formatter';
import './style.scss';

const Post = (props) => {
  const { post, siteMetadata, series, category } = props;
  const topics = post.fields.topicSlugs;

  const topicsBlock = topicsList => (topicsList
    ? (
      <div>
        <strong>
          Posted in: &nbsp;
        </strong>
        <ul className="post-single__topics-list">
          {topicsList.map((topic, i) => (
            <li className="post-single__topics-list-item" key={topic}>
              <Link to={topic} className="post-single__topics-list-item-link">
                {post.frontmatter.topics[i]}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
    : ''
  );

  return (
    <div>
      {post.frontmatter.featuredImage && (
        <div>
          <div className="post-single__featuredImageWrapper">
            <Img
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              className="post-single__featuredImage"
              alt="Cover image for post"
            />
            {post.frontmatter.featuredImageAttribution && (
              <div className="post-single__featuredImageAttribution">
                {post.frontmatter.featuredImageAttributionLink
                  ? (
                    <a href={post.frontmatter.featuredImageAttributionLink} target="_blank" rel="noopener noreferrer">
                      {post.frontmatter.featuredImageAttribution}
                    </a>
                  )
                  : post.frontmatter.featuredImageAttribution
                }
              </div>
            )}
          </div>
          <div className="post-single__featuredImageSpacer" />
        </div>
      )}
      <div>
        {/* <Link className="post-single__home-button" to={`/${category.path}`}> */}
        <Link className="post-single__home-button" to={`/posts`}>
          {/* Back to {category.title} */}
          Back to All Posts
        </Link>
      </div>
      <div className="post-single">
        <div className="post-single__inner">
          <h1 className="post-single__title">
            {post.frontmatter.title}
          </h1>
          <div className="post-single__postInfo">
            <div className="post-single__published">
              <strong>Published:</strong> {dateFormatter(post.frontmatter.date)} {post.frontmatter.updated ? <span>| <strong>Last Updated:</strong> {dateFormatter(post.frontmatter.updated)}</span> : ''}
            </div>
            {topicsBlock(topics)}
          </div>
          {series && (
            <div className="post-single__series">
              <p>
                The following is part of a series of posts called
                &quot;<span className="post-single__series--bold">{series.title}</span>&quot;.
              </p>
              {series.description && (
                <p dangerouslySetInnerHTML={{__html: series.description}}/>
              )}
              {!series.hidePosts && (
                <ul>
                  {series.posts.map(seriesPost => (
                    <li key={seriesPost.frontmatter.path}>
                      {seriesPost.frontmatter.path !== post.frontmatter.path
                        ? (
                          <Link to={seriesPost.frontmatter.path}>{seriesPost.frontmatter.title}</Link>
                        )
                        : seriesPost.frontmatter.title
                      }
                      &nbsp;-&nbsp;
                      {dateFormatter(seriesPost.frontmatter.date)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
          <div
            className="post-single__body"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
        <div className="post-single__footer">
          <div className="post-single__topics">
            {topicsBlock(topics)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;
